<template>
<div v-if = "photo">
    <section class="image-gallery">
      <img :src="photo.path" />
      <div class="photoInfo">
        <p class="photoTitle">{{photo.title}}</p>
        <p class="photoDescription">{{photo.description}}</p> 
        <p class="photoName">{{photo.user.firstName}} {{photo.user.lastName}}</p>
        <p class="photoDate">{{formatDate(photo.created)}}</p>
      </div>
  </section>
</div>
</template>

<script>
import axios from 'axios';
import moment from 'moment';

export default {
  name: 'photos',
    components: {
      
    },
  data () {
    return {
      photo: null,
  }
},
  async created() {
    try {
      let response = await axios.get('/api/photos/' + this.$route.params.id);
      this.$route.params.id = response.data.id;
      this.photo = response.data; 
    } catch (error) {
      this.$route.params.id = null;
    }
  },
  methods: {
    formatDate(date) {
      if (moment(date).diff(Date.now(), 'days') < 15)
        return moment(date).fromNow();
      else
        return moment(date).format('d MMMM YYYY');
    }
  },
}
</script>

<style scoped>
.photoInfo {
  text-align: center;
}

img {
  max-width: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: 2em;
  padding-top: 5em;
}
</style>
